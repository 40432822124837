<template>
  <div class="print-temperature-selector">
    <nh-button
      type="primary"
      @click="printPage"
    >
      {{ current.text }}
    </nh-button>

    <el-select
      v-if="current.type === TEMPERATURE_LAYER_BUTTON.type"
      :model-value="modelValue"
      filterable
      placeholder="请选择温层"
      :multiple="multiple"
      @change="onChange"
    >
      <el-option
        v-for="item in temperatureList"
        :key="item.temperatureLayerCode"
        :label="item.temperatureLayerName"
        :value="item.temperatureLayerCode"
      />
    </el-select>
  </div>
</template>

<script>

import PrintButtonConfig, {
  PRINT_BUTTON,
  TEMPERATURE_LAYER_BUTTON,
} from './config';

export default {
  props: {
    modelValue: {
      type: [String, Number, Array],
      default: '',
    },
    type: {
      type: String,
      default: PRINT_BUTTON.type,
    },
    temperatureList: {
      type: Array,
      default() {
        return [];
      },
    },
    beforePrint: {
      type: Function,
      default: null,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue', 'change'],
  data() {
    return {
      PRINT_BUTTON,
      TEMPERATURE_LAYER_BUTTON,
    };
  },
  computed: {
    // 计算当前按钮的配置
    current() {
      const buttonTypeKey = Object.keys(PrintButtonConfig)
        .find((key) => PrintButtonConfig[key].type === this.type);

      if (buttonTypeKey) {
        return {
          ...PrintButtonConfig[buttonTypeKey],
        };
      }

      return {
        ...PRINT_BUTTON,
      };
    },
  },
  methods: {
    async printPage() {
      if (this.beforePrint) {
        await this.beforePrint();
      }
      window.print();
    },
    onChange(temperatureCode) {
      this.$emit('update:modelValue', temperatureCode);
      this.$emit('change', temperatureCode);
    },
  },
};
</script>

<style lang="scss" scoped>
.print-temperature-selector {
  margin: 20px 0 50px 0;
}
</style>
