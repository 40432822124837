const XIAN_SHENG_HUO = '鲜生活冷链物流有限公司';
const DEFAULT_LOGO_SRC = require('../../../../assets/dyLogo.png');
// 打印单标题配置
const title = {
  oneiline: false, // 是否一行显示
  sheetName: '', // 打印单名称
  company: XIAN_SHENG_HUO, // 公司名称
  defaultLogoSrc: DEFAULT_LOGO_SRC, // logo
};

export default title;
