// 打印按钮
export const PRINT_BUTTON = {
  type: 'print',
  text: '打印',
};

// 按温层打印
export const TEMPERATURE_LAYER_BUTTON = {
  type: 'temperatureLayer',
  text: '按温层打印',
};

const PrintButtonConfig = {
  // 打印按钮
  PRINT_BUTTON,
  // 按温层打印
  TEMPERATURE_LAYER_BUTTON,
};

export default PrintButtonConfig;
