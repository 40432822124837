<template>
  <table
    class="print-table-component"
    :height="height"
    width="100%"
    cellpadding="0"
    cellspacing="0"
    style="table-layout: fixed;"
  >
    <!-- table标题 -->
    <caption>
      <slot name="title" />
    </caption>
    <!-- / table标题 -->

    <!-- 表头、页眉 -->
    <thead>
      <tr>
        <template v-for="(header, headerIndex) in headers">
          <th
            v-if="!header.hide"
            :key="headerIndex"
            :class="{
              'no-padding': header.children,
            }"
            :width="calculateWidth(header)"
          >
            <div v-html="header.label" />
            <div v-if="header.children" class="child-th">
              <div
                v-for="(child, childIndex) in header.children"
                :key="childIndex"
                v-html="child.label"
              />
            </div>
          </th>
        </template>
      </tr>
    </thead>
    <!-- / 表头、页眉 -->

    <!-- table正文、主体 -->
    <tbody>
      <tr v-for="(item, index) in list" :key="index">
        <template v-for="(header, headerIndex) in item.headers || headers">
          <td
            v-if="!header.hide"
            :key="headerIndex"
            class="chan-table-td"
            :class="[header.class, { 'relative-td': header.children }]"
            :style="setStyle(header, item)"
            :colspan="header.colspan || 1"
          >
            <slot :name="header.slot" :row="item" />
            <span v-if="!header.slot" v-html="formatTdText(header, item, index)" />

            <div v-if="header.children" class="child-td">
              <div
                v-for="(child, childIndex) in header.children"
                :key="childIndex"
                v-html="child.prop && item[child.prop] ? item[child.prop] : ''"
              />
            </div>
          </td>
        </template>
      </tr>
    </tbody>
    <!-- / table正文、主体 -->

    <!-- 表尾、页脚 -->
    <tfoot v-if="footers.length">
      <tr>
        <td
          v-for="(footer, footerIndex) in footers"
          :key="footerIndex"
          :width="footer.width"
          :colspan="footer.colspan || 1"
          v-html="footer.label"
        />
      </tr>
    </tfoot>
    <!-- / 表尾、页脚 -->
  </table>
</template>
<script>
import TABLE_CONFIG from './config';

export default {
  props: {
    type: {
      default: '',
      type: String,
    },
    height: {
      default: 'auto',
      type: String,
    },
    headers: {
      default() {
        return [];
      },
      type: Array,
    },
    list: {
      default() {
        return [];
      },
      type: Array,
    },
    footers: {
      default() {
        return [];
      },
      type: Array,
    },
  },
  data() {
    return {
      TABLE_CONFIG,
    };
  },
  methods: {
    calculateWidth(header) {
      return header.width || (TABLE_CONFIG[header.label] && TABLE_CONFIG[header.label].width) || '';
    },
    setStyle(header, item) {
      const style = {};
      const option = TABLE_CONFIG[header.label];
      const value = item[header.prop] || '';
      const maxLength = header.maxLength || (option && option.maxLength);
      const mediumLength = header.mediumLength || (option && option.mediumLength);

      // 如果字符串超过最大长度，缩小字号
      if (maxLength && value.length > maxLength) {
        style.fontSize = '12px';
      } else if (mediumLength && value.length > mediumLength) {
        style.fontSize = '14px';
      }

      style.width = header.width || (option && option.width) || '';

      return style;
    },
    formatTdText(header, item, listIndex) {
      let text = '';
      const option = TABLE_CONFIG[header.label];
      if (header.prop && header.prop === 'pickNum') {
        return item[header.prop];
      }
      if (!header.prop || (this.list.length - 1 === listIndex && header.noTotal === 'yes')) {
        return text;
      }

      if (item[header.prop]) {
        text = item[header.prop];
      } else if (header.showZero || (typeof header.showZero === 'undefined' && option && option.showZero)) {
        text = 0;
      }

      return text;
    },
  },
};
</script>

<style lang="scss" scoped>
.print-table-component {
  font-size: 16px;
  text-align: center;
  border-left: 1px solid #000;
  border-right: 1px solid #000;

  .number {
    font-size: 16px;
  }

  .no-padding {
    padding: 0;
  }

  .child-td,
  .child-th {
    div {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .child-td {
    display: flex;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    div + div {
      border-left: 1px solid #000;
    }
  }

  .child-th {
    display: flex;
    border-top: 1px solid #000;

    div + div {
      border-left: 1px solid #000;
    }
  }

  .relative-td {
    position: relative;
  }

  & + & {
    page-break-before: always;
  }

  th {
    padding: 2px 0;
    background: #f5f7fa;
  }

  td,
  th {
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;

    &.no-border {
      border-right: 0;
    }
  }

  tbody {
    td {
      height: 34px;
      word-break: break-all;
    }
  }

  td:last-child {
    border-right: none;
  }

  thead {
    tr {
      &:hover {
        background: #f5f7fa;
      }

      &:first-child {
        th {
          border-top: 1px solid #000;
        }
      }
    }
  }

  @media print {
    padding: 0;
  }
}
</style>
