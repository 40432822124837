const TABLE_CONFIG = {
  序号: {
    width: '30px',
  },
  辅助单位: {
    width: '40px',
  },
  辅助数量: {
    width: '40px',
  },
  温层: {
    width: '36px',
  },
  货主货品编码: {
    width: '100px',
  },
  实收数量: {
    width: '40px',
  },
  规格: {
    width: '100px',
    mediumLength: 10,
    maxLength: 11,
  },
  默认单位: {
    width: '36px',
  },
  换算数量: {
    showZero: true,
  },
  默认数量: {
    showZero: true,
  },
  数量: {
    width: '56px',
    maxLength: 4,
  },
};

export default TABLE_CONFIG;
