<template>
  <div class="print-page-header">
    <img
      v-if="!hideLogo"
      class="logo"
      :src="logoSrc"
    >
    <div class="title">
      <h3>
        {{ customTitle }}
        <span v-if="$title.oneLine && $title.sheetName">-{{ $title.sheetName }}</span>
      </h3>
      <h3 v-if="!$title.oneLine && $title.sheetName">
        {{ $title.sheetName }}
      </h3>
    </div>
    <div v-if="!hideBarcode" :class="['ptint-info', $barcode&&'ptint-barcode']">
      <nh-barcode
        v-if="$barcode"
        :value="$barcode.code"
        :text="$barcode.text"
        :size="showExt? 'small': 'big'"
        v-bind="barcodeConfig"
      />
      <div v-if="showExt" class="print-desc">
        打印人: {{ user.realName }} 打印时间/次数: {{ printTime }}/{{ printCount }}
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';

import { TEMPERATURE_LAYER } from '@/constant/temperatureLayer';
import { YMDHM } from '@/constant/timeFormat';
import titleConfig from './config';
import printSheet from '../../config/printSheet';
import { orderPrintTitleQuery, orderPrintLogoQuery } from './api';
// import { mapGetters } from 'vuex';
// import { selectByKey, cacheByKey } from '@/api/wms/print/print';
// import tables from '../config/table.js';
// import { BOLONG_TENANT, XIAN_SHENG_HUO } from '../constants/account';

export default {
  props: {
    // countKey: {
    //   default: '',
    //   type: String,
    // },
    // storageKey: {
    //   default: tables.STORAGE_KEY,
    //   type: String,
    // },
    titleName: {
      default: titleConfig.company,
      type: String,
    },
    barcode: {
      default: '',
      type: [String, Object],
    },
    barcodeConfig: {
      type: Object,
      default() {
        return {
          height: 35,
        };
      },
    },
    title: {
      type: Object,
      default() {
        return {};
      },
    },
    order: {
      default: '',
      type: String,
    },
    // 温层名称 || 温层code
    temperatureLayer: {
      default: '',
      type: String,
    },
    hideLogo: {
      default: false,
      type: Boolean,
    },
    // 展示打印人、打印时间信息
    showExt: {
      type: Boolean,
      default: true,
    },
    // 是否隐藏二维码
    hideBarcode: {
      default: false,
      type: Boolean,
    },
    printConfig: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      // tables,
      // type: '',
      // tableInfos: {
      //   lineNo: '',
      // },
      printTime: moment().format(YMDHM),
      printCount: 0,
      headerConfig: {
        customLogoSrc: '',
        customTitle: '',
      },
    };
  },
  computed: {
    ...mapState({
      user: ({ user }) => user.info || {},
    }),
    // ...mapGetters([
    //   'tenantId',
    //   'name',
    // ]),
    // _company() {
    //   if (this.company) {
    //     return this.company;
    //   }

    //   let company = XIAN_SHENG_HUO;

    //   if (!this.company && this.tenantId === BOLONG_TENANT.tenantId) {
    //     company = BOLONG_TENANT.name;
    //   }

    //   return company;
    // },
    // _title() {
    //   return this.title || this.type && this.tables[this.type] && this.tables[this.type].title;
    // },
    $title() {
      return {
        ...titleConfig,
        sheetName: this.getSheetName(),
        ...this.title,
      };
    },
    $barcode() {
      // 外部传入条码号，使用外部传入的条码
      if (this.barcode) {
        if (typeof this.barcode === 'string') {
          // 如果已有条码，使用条码
          return {
            code: this.barcode,
            text: '',
          };
        }

        return { ...this.barcode };
      }

      // start: 通过温层构建条码
      const barcode = {
        code: this.order,
        text: this.order,
      };

      const temperatureLayerInfo = TEMPERATURE_LAYER.find(({
        name,
        code,
      }) => name === this.temperatureLayer || code === this.temperatureLayer);

      if (temperatureLayerInfo) {
        barcode.code += `|${temperatureLayerInfo.code}`;
        barcode.text += `-${temperatureLayerInfo.alias}`;
      }

      return barcode;
      // end: 通过温层构建条码
    },
    logoSrc() {
      return this.headerConfig.customLogoSrc || titleConfig.defaultLogoSrc;
    },
    customTitle() {
      return this.headerConfig.customTitle || this.titleName;
    },
    shipperId() {
      if (this.printConfig.publicPrint && this.printConfig.publicPrint.shipperId) {
        return this.printConfig.publicPrint.shipperId;
      }
      if (this.printConfig.shipperId) {
        return this.printConfig.shipperId;
      }
      if (this.printConfig.goodsDetails && this.printConfig.goodsDetails.length > 0) {
        return this.printConfig.goodsDetails[0].shipperId;
      }
      if (this.printConfig.details && this.printConfig.details.length > 0) {
        return this.printConfig.details[0].shipperId;
      }
      return '';
    },
    shipperCode() {
      return this.printConfig.shipperCode;
    },
  },
  created() {
    if (this.shipperId || this.shipperCode) {
      this.getCustomLogoSrc();
      this.getCustomTitle();
    }
    // this.init();
  },
  methods: {
    getSheetName() {
      return printSheet.pickingList.sheetName;
    },
    async getCustomLogoSrc() {
      const result = await orderPrintLogoQuery({
        shipperId: this.shipperId,
        shipperCode: this.shipperCode,
      });
      this.headerConfig.customLogoSrc = result || '';
    },
    async getCustomTitle() {
      const result = await orderPrintTitleQuery({
        shipperId: this.shipperId,
        shipperCode: this.shipperCode,
      });
      this.headerConfig.customTitle = result || '';
    },
    // init() {
    //   const data = JSON.parse(localStorage.getItem(this.storageKey));
    //   this.tableInfos = data.tableInfos || data;
    //   this.type = data.type;

    //   this.tableInfos && this.selectByKey();
    // },
    // // 初始化打印次数
    // selectByKey() {
    //   const key = this.countKey || (this.tableInfos.id + this.type);

    //   selectByKey({ key }).then((res) => {
    //     this.printCount = res;
    //   }).catch((e) => {
    //     this.$message({ type: 'info', message: '获取数据失败！' });
    //   });
    // },
    // // 点击打印时，存储打印次数
    // cacheByKey() {
    //   const key = this.countKey || (this.tableInfos.id + this.type);

    //   cacheByKey({ key });
    // },
  },
};
</script>

<style lang="scss" scoped>
  .print-page-header {
    * {
      margin: 0;
      padding: 0;
    }

    position: relative;
    min-height: 56px;

    .logo {
      height: 50px;
      width: auto;
      position: absolute;
      left: 0;
      top: 0;
    }

    .title {
      text-align: center;
      font-size: 18px;
      font-weight: bold;
      line-height: 30px;

      h3 {
        font-size: 20px;
      }
    }

    .ptint-info {
      text-align: right;
      font-size: 12px;
      line-height: 26px;
      position: absolute;
      right: 0;
      top: 0;

      .print-desc {
        margin-top: -14px;
      }
    }
  }
</style>
