import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:获取打印单据的title
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/19263
 */
export function orderPrintTitleQuery(data = {}) {
  return wmsPlusHttp.post('/warehouse_management_system/order_print/title/query', data);
}

/**
 * @description:获取打印单据的logo
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/20111
 */
export function orderPrintLogoQuery(data = {}) {
  return wmsPlusHttp.post('/warehouse_management_system/order_print/logo/query', data);
}
